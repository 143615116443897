<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto">
        <v-card class="mx-auto" max-width="400">
          <v-card-title class="white--text primary">
            Available Images
            <v-spacer></v-spacer>
            <v-btn color="secondary" fab small>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pt-4">
            Select an image to edit/crop and prepare. Use the search field in the title
            bar to filter images.
          </v-card-text>

          <v-divider></v-divider>

          <v-virtual-scroll :items="imagesToShow" :item-height="50" height="1000">
            <template v-slot:default="{ item }">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn depressed small>
                    Edit
                    <v-icon color="blue-grey darken-4" right> mdi-pencil </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-col>

      <v-col cols="auto"> <Header /> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Header from "@/components/Header";

export default {
  components: {
    Header,
  },

  mounted() {
    this.$store.dispatch("menu/setQuickLink", "home");
  },

  asyncComputed: {
    imagesToShow: {
      async get() {
        try {
          // get the setup data
          console.log("Get image list");
          //var config = await dataloader.getConfig(this.configReloadKey);
          var images = [];
          for (var k = 0; k < 200; k++) {
            images.push({
              name: "test-" + k,
              description: "Tool Details " + k,
            });
          }
          return images;
        } catch (ex) {
          console.log("error 1");
          return [];
        }
      },
      default: [],
    },
  },
};
</script>
